import React from "react";

const statuses = {
  0: {
    text: "נסיעה לא פעילה",
    backgroundColor: "#e2e3e5",
    textColor: "#6c757d",
  },
  1: {
    text: "נסיעה פעילה",
    backgroundColor: "#d4edda",
    textColor: "#155724",
  },
};

const TripStatus = ({ status }) => {
  const statusInfo = statuses[status] || {
    text: "Unknown",
    backgroundColor: "#ffffff",
    textColor: "#000000",
  };

  const containerStyle = {
    padding: "8px",
    borderRadius: "8px",
    textAlign: "center",
    backgroundColor: statusInfo.backgroundColor,
    color: statusInfo.textColor,
    fontWeight: "bold",
    fontSize: "16px",
  };

  return <div style={containerStyle}>{statusInfo.text}</div>;
};

export default TripStatus;
