import React from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import ClientDetailsInfoTab from "./ClientDetailsInfoTab";
import ClientOrganizersTab from "./ClientOrganizersTab";
import ClientFeeTab from "./ClientFeeTab";
import ClientDocumentsTab from "./ClientDocumentsTab";
import ClientSettingsTab from "./ClientsSettingsTab";

export default function ClientDetailsContentSection({
  handleTabSelect,
  client,
  isEditing,
  editedClient,
  handleInputChange,
  handleCancelEdit,
  handleEditToggle,
  handleImageUpload,
  handleSaveChanges,
  setToastType,
  setShowToast,
  setToastMessage,
  fetchClientDetails,
}) {
  return (
    <>
      <Card className="border border-1">
        <Card.Body className="p-3">
          <Tabs
            defaultActiveKey="details"
            id="trip-details-tabs"
            className="mb-3"
            onSelect={handleTabSelect}
          >
            <Tab eventKey="details" title="פרטים">
              <ClientDetailsInfoTab
                client={client}
                isEditing={isEditing}
                editedClient={editedClient}
                handleInputChange={handleInputChange}
                handleCancelEdit={handleCancelEdit}
                handleEditToggle={handleEditToggle}
                handleImageUpload={handleImageUpload}
                handleSaveChanges={handleSaveChanges}
                setToastMessage={setToastMessage}
                setToastType={setToastType}
                setShowToast={setShowToast}
              />
            </Tab>

            <Tab eventKey="organizers" title="סדרנים">
              <ClientOrganizersTab
                client={client}
                setToastMessage={setToastMessage}
                setToastType={setToastType}
                setShowToast={setShowToast}
              />
            </Tab>

            <Tab eventKey="fee" title="עמלות">
              <ClientFeeTab
                client={client}
                setToastMessage={setToastMessage}
                setToastType={setToastType}
                setShowToast={setShowToast}
              />
            </Tab>

            <Tab eventKey="documents" title="מסמכים">
              <ClientDocumentsTab
                client={client}
                setToastMessage={setToastMessage}
                setToastType={setToastType}
                setShowToast={setShowToast}
                fetchClientDetails={fetchClientDetails}
              />
            </Tab>

            <Tab eventKey="settings" title="אחר">
              <ClientSettingsTab
                client={client}
                setToastMessage={setToastMessage}
                setToastType={setToastType}
                setShowToast={setShowToast}
                fetchClientDetails={fetchClientDetails}
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
}
