import React from "react";
import { Card } from "react-bootstrap";
import TripDriverStatus from "../../../components/TripDriverStatus/TripDriverStatus";
import Label from "../../../components/Label/Label";
import { formatDateAndTime } from "../../../utils/supportFunctions";
import TripStatus from "../../../components/TripStatus/TripStatus";

export default function OrderDetailsHeaderSection({ tripDetails }) {
  return (
    <>
      <Card className="border border-1">
        <Card.Body className="d-flex justify-content-between align-items-center p-3">
          <div className="w-100">
            <>
              <div className="d-flex flex-column gap-3">
                <Card.Title className="d-flex justify-content-between w-100">
                  <div className="d-flex flex-column justify-content-start align-items-start gap-2">
                    <span className="font-weight-bold">
                      {tripDetails?.lineDescription}
                    </span>
                    <div className="d-flex gap-2">
                      <TripStatus status={tripDetails?.isActive} />
                      {/* {tripDetails?.isActive === "1" && (
                        <TripDriverStatus status={tripDetails?.isActive} />
                      )} */}
                    </div>
                  </div>
                </Card.Title>
                <div className="d-flex flex-wrap gap-2">
                  <Label
                    title="תאריך פרסום"
                    content={formatDateAndTime(tripDetails?.createdAt)}
                  />
                  <Label
                    title="שינוי אחרון"
                    content={formatDateAndTime(tripDetails?.updatedAt)}
                  />
                  <Label
                    title="סנכרון אחרון"
                    content={formatDateAndTime(tripDetails?.lastSyncedAt)}
                  />
                </div>
              </div>
            </>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
