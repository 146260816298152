import React, { useState } from "react";
import { Button, Form, Modal, Spinner, Alert } from "react-bootstrap";
import { getClientTypeText } from "../../../../utils/supportFunctions";
import { BsPencilSquare } from "react-icons/bs";

export default function ClientDetailsInfoTab({
  client,
  editedClient,
  handleInputChange,
  handleCancelEdit,
  handleEditToggle,
  handleSaveChanges,
}) {
  const [showModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // Loading state
  const [error, setError] = useState(""); // Error message state

  const handleShowModal = () => {
    setShowModal(true);
    handleEditToggle();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    handleCancelEdit();
    setError(""); // Clear errors when closing
  };

  const handleSaveAndCloseModal = async () => {
    setIsSaving(true);
    setError("");
    try {
      await handleSaveChanges();
      setShowModal(false);
    } catch (e) {
      setError("Failed to save changes. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div
      className="card-body"
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      <div className="row">
        <div className="col-md-10">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div className="font-weight-bold">סוג לקוח:</div>
            <div>{getClientTypeText(client?.clientType)}</div>

            <div className="font-weight-bold">מזהה חברה:</div>
            <div>{client.llsNumber}</div>

            <div className="font-weight-bold">מייל הנה״ח:</div>
            <div>{client.billingEmail}</div>

            <div className="font-weight-bold">קוד נהג:</div>
            <div>{client.clientDriverCode || "לא צויין"}</div>
          </div>
        </div>
        <div className="col-md-2 justify-content-end d-flex align-items-start">
          <Button variant="outline-secondary" onClick={handleShowModal}>
            <BsPencilSquare />
          </Button>
        </div>
      </div>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>עריכת פרטי לקוח</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Alert variant="danger" className="mb-3">
              {error}
            </Alert>
          )}
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>שם החברה:</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={editedClient?.name || ""}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>סוג החברה:</Form.Label>
              <Form.Select
                name="clientType"
                value={editedClient?.clientType || ""}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  בחר סוג חברה
                </option>
                <option value={1}>חברה פרטית</option>
                <option value={2}>חברה ציבורית</option>
                <option value={3}>עוסק מורשה</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>מזהה החברה:</Form.Label>
              <Form.Control
                type="text"
                name="llsNumber"
                value={editedClient?.llsNumber || ""}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>מייל הנה״ח:</Form.Label>
              <Form.Control
                type="email"
                name="billingEmail"
                value={editedClient?.billingEmail || ""}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>קוד נהג:</Form.Label>
              <Form.Control
                type="text"
                name="clientDriverCode"
                value={editedClient?.clientDriverCode || ""}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={handleCloseModal}
            disabled={isSaving}
          >
            ביטול
          </Button>
          <Button
            variant="success"
            onClick={handleSaveAndCloseModal}
            disabled={isSaving}
          >
            {isSaving ? <Spinner animation="border" size="sm" /> : "שמור"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
