import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { BsExclamationTriangleFill } from "react-icons/bs";
import AreYouSureModal from "../../../components/AreYouSureModal/AreYouSureModal";
import { fetchData } from "../../../utils/fetchData";
import { useNavigate } from "react-router-dom";

const DangerZone = ({
  client,
  setToastMessage,
  setShowToast,
  setToastType,
  fetchClientDetails,
}) => {
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [loadingToggleAccess, setLoadingToggleAccess] = useState(false);
  const [loadingDeleteClient, setLoadingDeleteClient] = useState(false);
  const navigate = useNavigate();

  // Function to handle client deletion
  const handleDeleteClient = async () => {
    setLoadingDeleteClient(true); // Start loading
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      const response = await fetchData(
        { request: `clients/${client?._id}` },
        "DELETE",
        token
      );

      setToastMessage(response.message);
      setToastType("success");
      setShowToast(true);

      // Redirect to /dashboard/clients after successful deletion
      navigate("/dashboard/clients");
    } catch (error) {
      console.error("Error deleting client:", error);
      setToastMessage(error.message);
      setToastType("danger");
      setShowToast(true);
    } finally {
      setLoadingDeleteClient(false); // End loading
    }
  };

  // Function to handle access disabling
  const handleToggleAccess = async () => {
    setLoadingToggleAccess(true); // Start loading
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      // Determine the action (enable or disable)
      const action = client.isActive ? "disable" : "enable";

      const response = await fetchData(
        { request: `clients/toggle-access/${client?._id}` },
        "PUT",
        token
      );

      setToastMessage(response.message);
      setToastType("success");
      setShowToast(true);
      fetchClientDetails();
    } catch (error) {
      console.error(`Error toggling client access:`, error);
      setToastMessage(error.message);
      setToastType("danger");
      setShowToast(true);
    } finally {
      setLoadingToggleAccess(false); // End loading
    }
  };

  return (
    <div>
      <div className="bg-white text-danger">
        <BsExclamationTriangleFill className="me-2" />
        איזור רגיש
      </div>
      <div>
        <p>
          שים לב - במקרה של מחיקת לקוח לא יהיה ניתן לבטל פעולה זו, וכל המידע של
          הלקוח ימחק לצמיתות!
        </p>

        <Button
          variant={client.isActive ? "warning" : "success"}
          onClick={handleToggleAccess}
          disabled={loadingToggleAccess} // Disable button during loading
        >
          {loadingToggleAccess
            ? "מבצע פעולה..." // Show loading text
            : client.isActive
            ? "חסום גישה ללקוח"
            : "פתח גישה ללקוח"}
        </Button>

        {/* <Button
          variant="danger"
          className="ms-2"
          onClick={() => setIsAreYouSureOpen(true)}
          disabled={loadingDeleteClient} // Disable button during loading
        >
          {loadingDeleteClient ? "מוחק לקוח..." : "מחק לקוח"}
        </Button> */}
      </div>

      {/* Are You Sure Modal for delete confirmation */}
      <AreYouSureModal
        isAreYouSureOpen={isAreYouSureOpen}
        closeAreYouSureModal={() => setIsAreYouSureOpen(false)}
        handleApprove={handleDeleteClient}
        modalTitle="האם אתה בטוח?"
        modalMessage={`האם אתה בטוח שברצונך למחוק את הלקוח ${client?.name}? לא ניתן לבטל פעולה זו.`}
      />
    </div>
  );
};

export default DangerZone;
