import React from "react";
import { Button } from "react-bootstrap";
import { IoChevronBackOutline } from "react-icons/io5";

export default function BackButton() {
  return (
    <div>
      <Button
        className="border border-1 bg-white text-dark"
        onClick={() => {
          window.history.back();
        }}
        style={{
          height: "50px",
          width: "50px",
        }}
      >
        <IoChevronBackOutline
          size={24}
          style={{
            transform: "scaleX(-1)",
          }}
        />
      </Button>
    </div>
  );
}
