import React from "react";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { format } from "date-fns";
import { he } from "date-fns/locale";
import OrderDetailsHistoryFetchData from "./OrderDetailsHistoryFetchData";

export default function OrderDetailsContentSection({
  tripDetails,
  handleTabSelect,
}) {
  const orderStartTime = tripDetails?.orderStartTime ?? null;
  const orderEndTime = tripDetails?.orderEndTime ?? null;

  let duration = "N/A";

  if (orderStartTime && orderEndTime) {
    const start = new Date(orderStartTime);
    const end = new Date(orderEndTime);
    const diffMs = end - start;

    const hours = Math.floor(diffMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    duration = `${formattedHours}:${formattedMinutes}`;
  }

  const formattedDate = orderStartTime
    ? format(new Date(orderStartTime), "dd.MM.yyyy", { locale: he })
    : "N/A";

  const formattedDay = orderStartTime
    ? format(new Date(orderStartTime), "EEEE", { locale: he }).replace(
        "יום ",
        ""
      )
    : "N/A";

  const startTime = orderStartTime
    ? format(new Date(orderStartTime), "HH:mm")
    : "N/A";

  const endTime = orderEndTime
    ? format(new Date(orderEndTime), "HH:mm")
    : "N/A";

  const numOfPassengers = tripDetails?.stations.reduce(
    (total, station) => total + station.passengers.length,
    0
  );

  return (
    <>
      <Card className="border border-1">
        <Card.Body className="p-3">
          <Tabs
            defaultActiveKey="details"
            id="trip-details-tabs"
            className="mb-3"
            onSelect={handleTabSelect}
          >
            {/* Tab 1: Details */}
            <Tab eventKey="details" title="פרטים">
              <div
                className="card-body"
                style={{
                  height: "480px",
                  maxHeight: "480px",
                  overflowY: "auto",
                }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div className="font-weight-bold">תאריך:</div>
                      <div className="d-flex justify-content-between">
                        {formattedDate}

                        <div className="font-weight-bold">יום:</div>
                        <div>{formattedDay}</div>
                      </div>

                      <hr style={{ gridColumn: "1 / 3", width: "100%" }} />

                      <div className="font-weight-bold">סוג הרכב:</div>
                      <div>{tripDetails?.orderCarType}</div>

                      <div className="font-weight-bold">שעת התחלה:</div>
                      <div>{startTime}</div>

                      <div className="font-weight-bold">שעת סיום:</div>
                      <div>{endTime}</div>

                      <div className="font-weight-bold">משך הנסיעה:</div>
                      <div>{duration}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div className="font-weight-bold">קוד נסיעה:</div>
                      <div className="d-flex gap-4">
                        {tripDetails?.lineCode}
                      </div>

                      <hr style={{ gridColumn: "1 / 3", width: "100%" }} />

                      <div className="font-weight-bold">נהג:</div>
                      <div>{tripDetails?.driverNickName}</div>

                      <div className="font-weight-bold">טלפון נהג:</div>
                      <div>{tripDetails?.driverMobilePhone}</div>

                      <div className="font-weight-bold"> מספר ויזה:</div>
                      <div>{tripDetails?.visaNumber}</div>

                      <div className="font-weight-bold">סוג נסיעה:</div>
                      <div>{tripDetails?.lineType}</div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <hr style={{ gridColumn: "1 / 3", width: "100%" }} />

                      <div className="font-weight-bold">הערות:</div>
                      <div>
                        {tripDetails?.shortRemarks === ""
                          ? "אין הערות"
                          : tripDetails?.shortRemarks}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            {/* Tab 2: Stations and Passengers */}
            <Tab eventKey="stations" title="תחנות">
              <div
                className="card-body"
                style={{
                  height: "480px",
                  maxHeight: "480px",
                  overflowY: "auto",
                }}
              >
                <Row>
                  <Col className="d-flex gap-4">
                    <div className="d-flex gap-1">
                      <span className="font-weight-bold">סה״כ תחנות:</span>
                      <span>{tripDetails?.stations?.length}</span>
                    </div>
                    <div className="d-flex gap-1">
                      <span className="font-weight-bold">סה״כ נוסעים:</span>
                      <span>{numOfPassengers}</span>
                    </div>
                  </Col>
                </Row>
                <div className="row">
                  {tripDetails.stations.map((station, index) => (
                    <div className="col-md-4" key={index}>
                      <Card className="mb-3 shadow-sm">
                        <Card.Body>
                          <Card.Title>
                            <strong>תחנה:</strong>{" "}
                            {`${station.stationStreet} ${station.stationHouse}, ${station.stationCity}`}
                          </Card.Title>
                          <p>
                            <strong>זמן תחנה:</strong> {station.stationTime}
                          </p>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            </Tab>

            {/* Tab 3: Stations and Passengers */}
            <Tab
              eventKey="notes"
              title={
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span>הערות</span>
                  {tripDetails?.longRemarks &&
                    tripDetails?.longRemarks !== "" && (
                      <div
                        style={{
                          width: "16px",
                          height: "16px",
                          backgroundColor: "red",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                        title="יש הערות"
                      >
                        !
                      </div>
                    )}
                </div>
              }
            >
              <div
                className="card-body"
                style={{
                  height: "480px",
                  maxHeight: "480px",
                  overflowY: "auto",
                }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                        gap: "10px",
                      }}
                    >
                      <div className="font-weight-bold">הערות:</div>
                      <div>
                        {tripDetails?.longRemarks === ""
                          ? "אין הערות"
                          : tripDetails?.longRemarks}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            {/* Tab 4: History */}
            <Tab eventKey="history" title="היסטוריה">
              <OrderDetailsHistoryFetchData lineCode={tripDetails?.lineCode} />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
}
