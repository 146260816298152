import React from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";

export default function ClientDetailsCardTripsItem({
  title,
  data = [],
  totalCalculation = null,
  tooltip = null, // Optional tooltip prop
}) {
  return (
    <Card className="border border-1 h-100">
      <Card.Body className="d-flex flex-column align-items-start p-3">
        <Card.Title className="font-weight-bold d-flex align-items-center">
          {title}
          {tooltip && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id={`tooltip-${title}`}>{tooltip}</Tooltip>}
            >
              <span className="ms-2" style={{ cursor: "pointer" }}>
                <BsInfoCircle style={{ height: "15px" }} />
              </span>
            </OverlayTrigger>
          )}
        </Card.Title>
        {data.map((item, index) => (
          <Card.Text
            key={index}
            className="d-flex justify-content-between align-items-center w-100"
          >
            <span className="font-weight-bold">{item.label}</span>
            <span>{item.value}</span>
          </Card.Text>
        ))}
        {totalCalculation && (
          <Card.Text className="d-flex justify-content-between align-items-center w-100">
            <span className="font-weight-bold">לתשלום</span>
            <span>{totalCalculation}</span>
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
}
