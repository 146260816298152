import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  checkClientExists,
  fetchClientIdByDriverCode,
} from "../../../utils/supportFunctions";
import { useNavigate } from "react-router-dom";

export default function OrderDetailsRevenueSection({ tripDetails }) {
  const [clientDetails, setClientDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientDetails = async () => {
      const client = await checkClientExists(tripDetails?.clientName);
      setClientDetails(client);
    };

    fetchClientDetails();
  }, [tripDetails?.clientName]);

  const calculatePublisherFee = () => {
    if (!tripDetails?.netClientPrice || !tripDetails?.publisherFee) return 0;
    return tripDetails.netClientPrice * (tripDetails.publisherFee / 100);
  };

  const calculateTakerFee = () => {
    if (!tripDetails?.netClientPrice || !tripDetails?.takerFee) return 0;
    return tripDetails.netClientPrice * (tripDetails.takerFee / 100);
  };

  const totalFees = calculatePublisherFee() + calculateTakerFee();

  const handleNavigateToDriverClient = async (driverCode) => {
    try {
      if (!driverCode) {
        alert("קוד נהג חסר.");
        return;
      }

      const clientId = await fetchClientIdByDriverCode(driverCode);

      if (clientId) {
        navigate(`/dashboard/clients/${clientId}`);
      } else {
        alert("לא נמצא לקוח מתאים לקוד הנהג.");
      }
    } catch (error) {
      console.error("Error navigating to client page:", error);
    }
  };

  const handleNavigateToPublisherClient = async (clientName) => {
    try {
      if (!clientName) {
        alert("שם חברה חסר.");
        return;
      }

      const clientId = await checkClientExists(tripDetails?.clientName);
      if (clientId) {
        navigate(`/dashboard/clients/${clientId.id}`);
      } else {
        alert("לא נמצא לקוח מתאים לשם החברה.");
      }
    } catch (error) {
      console.error("Error navigating to client page:", error);
    }
  };

  return (
    <Row className="align-items-stretch row-gap-3">
      {/* Publisher Section */}
      <Col xs={12} md={4}>
        <Card className="border border-1 h-100">
          <Card.Header style={{ background: "#d4edda", color: "#155724" }}>
            <span className="font-weight-bold">חברה מציעה</span>
          </Card.Header>
          <Card.Body className="d-flex justify-content-between align-items-center p-3">
            <div className="w-100">
              <div className="d-flex flex-column gap-3">
                <Card.Title className="d-flex justify-content-between w-100">
                  <span
                    className="font-weight-bold hover-link"
                    onClick={() =>
                      handleNavigateToPublisherClient(tripDetails?.clientName)
                    }
                  >
                    {tripDetails?.clientName ?? "-"}
                  </span>
                </Card.Title>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-between w-100">
            <span className="font-weight-bold">
              מ.פרסום: {tripDetails?.netClientPrice ?? "-"}
            </span>
            |
            <span className="font-weight-bold">
              עמלה: {tripDetails?.publisherFee ?? "-"}%
            </span>
            |
            <span className="font-weight-bold">
              לתשלום:{" "}
              {(
                tripDetails?.netClientPrice + calculatePublisherFee()
              ).toLocaleString()}
            </span>
          </Card.Footer>
        </Card>
      </Col>

      {/* Taker Section */}
      <Col xs={12} md={4}>
        <Card className="border border-1 h-100">
          <Card.Header style={{ background: "#fff4cc", color: "#cc7a00" }}>
            <span className="font-weight-bold">חברה מבצעת</span>
          </Card.Header>
          <Card.Body className="d-flex justify-content-between align-items-center p-3">
            <div className="w-100">
              <div className="d-flex flex-column gap-3">
                <Card.Title className="d-flex justify-content-between w-100">
                  <span
                    className="font-weight-bold hover-link"
                    onClick={() =>
                      handleNavigateToDriverClient(tripDetails?.driverCode)
                    }
                  >
                    {tripDetails?.performingCompanyName === ""
                      ? "-"
                      : tripDetails?.performingCompanyName}
                  </span>
                </Card.Title>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-between w-100">
            <span className="font-weight-bold">
              מ.לקיחה: {tripDetails?.netClientPrice ?? "-"}
            </span>
            |
            <span className="font-weight-bold">
              עמלה: {tripDetails?.takerFee ?? "-"}
              {tripDetails?.takerFee > 0 && "%"}
            </span>
            |
            <span className="font-weight-bold">
              לתשלום:{" "}
              {tripDetails?.takerFee > 0
                ? tripDetails?.netClientPrice -
                  calculateTakerFee().toLocaleString()
                : "-"}
            </span>
          </Card.Footer>
        </Card>
      </Col>

      {/* Fee Details Section */}
      <Col xs={12} md={4}>
        <Card className="border border-1 h-100">
          <Card.Header>
            <span className="font-weight-bold">פירוט עמלות</span>
          </Card.Header>
          <Card.Body className="d-flex justify-content-between align-items-center p-3">
            <div className="w-100">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex justify-content-between w-100">
                  <span className="font-weight-bold">חברה מציעה:</span>
                  <span className="font-weight-bold">
                    {calculatePublisherFee().toFixed(2)}
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <span className="font-weight-bold">חברה מבצעת:</span>
                  <span className="font-weight-bold">
                    {calculateTakerFee().toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-between w-100">
            <span className="font-weight-bold">סה"כ:</span>
            <span className="font-weight-bold">{totalFees.toFixed(2)}</span>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
}
