import React from "react";

const statuses = {
  true: {
    text: "לקוח פעיל",
    backgroundColor: "#d4edda",
    textColor: "#155724",
  },
  false: {
    text: "לקוח לא פעיל",
    backgroundColor: "#e2e3e5",
    textColor: "#6c757d",
  },
};

const ClientStatus = ({ status }) => {
  const statusInfo = statuses[status] || {
    text: "Unknown",
    backgroundColor: "#ffffff",
    textColor: "#000000",
  };

  const containerStyle = {
    padding: "8px",
    borderRadius: "8px",
    textAlign: "center",
    backgroundColor: statusInfo.backgroundColor,
    color: statusInfo.textColor,
    fontWeight: "bold",
    fontSize: "16px",
  };

  return <div style={containerStyle}>{statusInfo.text}</div>;
};

export default ClientStatus;
