import React, { useState, useEffect } from "react";
import { Form, Row, InputGroup, Button } from "react-bootstrap";
import { fetchData } from "../../../../utils/fetchData";

const ClientFeeTab = ({
  client,
  setToastType,
  setShowToast,
  setToastMessage,
}) => {
  const [editedFees, setEditedFees] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (client) {
      setEditedFees({
        publisherFee: client?.publisherFee ?? "",
        takerFee: client?.takerFee ?? "",
      });
    }
  }, [client]);

  const handleFeeChange = (field, value) => {
    setEditedFees((prevFees) => ({
      ...prevFees,
      [field]: value,
    }));
  };

  const handleSaveFees = async () => {
    setLoading(true);
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData)?.token;

      await fetchData(
        { request: `clients/${client?._id}/fees`, body: editedFees },
        "PUT",
        token
      );

      setToastMessage("העמלה שונתה בהצלחה");
      setToastType("success");
      setShowToast(true);
    } catch (error) {
      console.error("Error updating fees:", error.message);
      setToastMessage("שגיאה בעדכון עמלות");
      setToastType("danger");
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="card-body"
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      <Form>
        <Row className="mb-3">
          <div>
            <Form.Group controlId="publisherFee" style={{ width: "30%" }}>
              <Form.Label className="font-weight-bold">
                עמלה חברה מציעה
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  value={editedFees?.publisherFee || ""}
                  onChange={(e) =>
                    handleFeeChange("publisherFee", e.target.value)
                  }
                  min="0"
                />
                <InputGroup.Text className="px-3">%</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </div>
          <hr className="my-3" />
          <div>
            <Form.Group controlId="takerFee" style={{ width: "30%" }}>
              <Form.Label className="font-weight-bold">
                עמלה חברה מבצעת
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  value={editedFees?.takerFee || ""}
                  onChange={(e) => handleFeeChange("takerFee", e.target.value)}
                  min="0"
                />
                <InputGroup.Text className="px-3">%</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </div>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            onClick={handleSaveFees}
            disabled={loading}
            className="table-btn"
          >
            {loading ? "שומר..." : "שמור שינויים"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ClientFeeTab;
