import React, { useState, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { fetchData } from "../../utils/fetchData";
import { formatDateAndTime } from "../../utils/supportFunctions";

// Function to send a note to the server
const sendNote = async (id, noteText, context) => {
  try {
    const userData = localStorage.getItem("userData");
    const token = JSON.parse(userData).token;

    const endpoint =
      context === "trip" ? `trip/${id}/notes` : `clients/${id}/notes`;

    const response = await fetchData(
      {
        request: endpoint,
        body: { text: noteText },
      },
      "POST",
      token
    );

    if (response && response.success) {
      return response.note; // Return the newly added note
    } else {
      console.error("Error adding note:", response.message);
      return null;
    }
  } catch (error) {
    console.error("Error sending note:", error.message);
    return null; // Return null in case of an error
  }
};

// Function to fetch notes based on context
const fetchNotes = async (id, context) => {
  try {
    const userData = localStorage.getItem("userData");
    const token = JSON.parse(userData).token;

    const endpoint =
      context === "trip" ? `trip/${id}/notes` : `clients/${id}/notes`;

    const response = await fetchData({ request: endpoint }, "GET", token);

    if (response && response.success) {
      return response.notes; // Return the notes array
    } else {
      console.error("Error fetching notes:", response.message);
      return [];
    }
  } catch (error) {
    console.error("Error fetching notes:", error.message);
    return []; // Return an empty array if there is an error
  }
};

export default function NotesSection({ id, context }) {
  const [notes, setNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState("");

  // Fetch notes when the component loads or context changes
  useEffect(() => {
    const loadNotes = async () => {
      const notesFromServer = await fetchNotes(id, context);
      setNotes(notesFromServer); // Update notes in the UI
    };

    loadNotes();
  }, [id, context]);

  const handleSendNote = async () => {
    if (currentNote.trim()) {
      const newNote = await sendNote(id, currentNote.trim(), context);
      if (newNote) {
        setNotes((prev) => [...prev, newNote]); // Add the new note to the UI
        setCurrentNote("");
      }
    }
  };

  return (
    <Card className="border border-1">
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <span className="font-weight-bold">
            {context === "trip"
              ? "הערות נציגים על נסיעה"
              : "הערות נציגים על לקוח"}
          </span>
          <span className="text-muted small">
            ({notes.length === 1 ? "" : notes.length}
            {notes.length === 1 ? "הערה אחת" : " הערות"})
          </span>
        </div>
      </Card.Header>
      <Card.Body className="p-3">
        <div className="mb-3">
          <div className="d-flex flex-column gap-3">
            <div
              style={{
                height: "400px",
                maxHeight: "400px",
                overflowY: "auto",
              }}
              className="border rounded p-2 bg-light"
            >
              {notes.length === 0 ? (
                <div
                  className="text-center text-muted py-3"
                  style={{ maxHeight: "100px" }}
                >
                  אין הערות להצגה
                </div>
              ) : (
                notes.map((note) => (
                  <div
                    key={note.id}
                    className="border p-3 rounded bg-white mb-2"
                    style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                  >
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <span className="font-weight-bold">
                        {note.id}. {note.author}
                      </span>
                      <span className="text-muted small">
                        {formatDateAndTime(note.timestamp)}
                      </span>
                    </div>
                    <div>{note.text}</div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <Form.Group className="mb-3">
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="הכנס הערה כאן..."
            value={currentNote}
            onChange={(e) => setCurrentNote(e.target.value)}
            style={{
              resize: "none",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          />
        </Form.Group>
        <div className="d-flex justify-content-end w-100">
          <Button variant="primary" onClick={handleSendNote}>
            שלח הערה
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
