import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import { fetchData } from "../../../../utils/fetchData";

const ClientDocumentsTab = ({
  client,
  fetchClientDetails,
  setToastType,
  setShowToast,
  setToastMessage,
}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      setToastMessage("לא נבחר קובץ");
      setToastType("warning");
      setShowToast(true);
      return;
    }

    const formData = new FormData();
    formData.append("document", file);

    setLoading(true);
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData)?.token;

      const response = await fetchData(
        { request: `clients/upload_document/${client._id}`, body: formData },
        "POST",
        token
      );

      if (response.success) {
        setToastMessage("המסמך הועלה בהצלחה");
        setToastType("success");
        setShowToast(true);
        await fetchClientDetails(); // Fetch updated client data
        setFile(null); // Reset the file input
      } else {
        throw new Error(response.message || "שגיאה בהעלאת המסמך");
      }
    } catch (error) {
      setToastMessage(error.message || "שגיאה בהעלאת המסמך");
      setToastType("danger");
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFile = async () => {
    setShowConfirmModal(false); // Close modal
    setLoading(true);
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData)?.token;

      const response = await fetchData(
        { request: `clients/remove_document/${client._id}` },
        "DELETE",
        token
      );

      if (response.success) {
        setToastMessage("המסמך הוסר בהצלחה");
        setToastType("success");
        setShowToast(true);
        await fetchClientDetails(); // Refresh client data to reflect the changes
      } else {
        throw new Error(response.message || "שגיאה בהסרת המסמך");
      }
    } catch (error) {
      setToastMessage(error.message || "שגיאה בהסרת המסמך");
      setToastType("danger");
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="card-body"
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      <div>
        {/* Display Document */}
        {client.document_file ? (
          client.document_file.endsWith(".pdf") ? (
            <iframe
              src={client.document_file}
              width="100%"
              height="400"
              title="Document Viewer"
              style={{ border: "none" }}
            />
          ) : (
            <div>
              <p>המסמך אינו קובץ PDF. לחץ להורדה:</p>
              <a
                href={client.document_file}
                target="_blank"
                rel="noopener noreferrer"
              >
                {client.document_file.split("/").pop()}
              </a>
            </div>
          )
        ) : (
          <Form onSubmit={handleUploadFile}>
            <Form.Group controlId="formFile" className="pb-3">
              <Form.Label>בחר קובץ מסמך</Form.Label>
              <Form.Control
                type="file"
                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={handleFileChange}
              />
            </Form.Group>
            <div className="d-flex justify-content-end w-100">
              <Button
                variant="primary"
                disabled={loading}
                className="table-btn"
                type="submit"
              >
                {loading ? "מעלה..." : "שמור קובץ"}
              </Button>
            </div>
          </Form>
        )}

        {/* Delete Document */}
        {client.document_file && (
          <Button
            variant="outline-danger"
            onClick={() => setShowConfirmModal(true)}
            disabled={loading}
            className="mt-3 d-flex align-items-center"
          >
            <BsTrash />
            <span className="ms-2">מחיקת מסמך</span>
          </Button>
        )}
      </div>

      {/* Confirm Delete Modal */}
      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>האם אתה בטוח?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          האם אתה בטוח שברצונך למחוק את המסמך? פעולה זו אינה ניתנת לביטול.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmModal(false)}
          >
            ביטול
          </Button>
          <Button variant="danger" onClick={handleRemoveFile}>
            מחיקה
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClientDocumentsTab;
