import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { BsCamera } from "react-icons/bs";
import ClientStatus from "../../../components/ClientStatus/ClientStatus";

export default function ClientDetailsHeader({ client, handleImageUpload }) {
  return (
    <Card className="border border-1">
      <div className="d-flex justify-content-center  align-items-center py-3">
        <Row className="justify-content-center align-items-center w-100">
          <Col md="2">
            <div className="d-flex align-items-center justify-content-center">
              {client.profileImage && (
                <div className="profile-container">
                  <img
                    src={client.profileImage}
                    alt="Profile"
                    className="profile-image"
                  />
                  <Button
                    variant="light"
                    className="camera-icon"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <BsCamera size={15} />
                  </Button>
                  <Form.Control
                    id="fileInput"
                    type="file"
                    onChange={handleImageUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col md="10">
            <div className="w-100">
              <div>
                <Card.Title className="d-flex justify-content-between w-100">
                  <span className="font-weight-bold">{client.name}</span>
                </Card.Title>

                <div style={{ width: "30%" }}>
                  <ClientStatus status={client?.isActive} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
}
