import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchData } from "../../../utils/fetchData";
import { Container, Row, Col } from "react-bootstrap";
import ToastComponent from "../../../components/Toast/Toast";
import "./ClientDetails.scss";
import LoaderComponent from "../../../components/LoaderComponent/LoaderComponent";
import ClientTransactions from "./ClientTransactions";
import ClientDetailsHeader from "./ClientDetailsHeader";

import ClientCardTripsSection from "./ClientCardTripsSection/ClientCardTripsSection";
import BackButton from "../../../components/BackButton/BackButton";
import ClientDetailsContentSection from "./ClientDetailsContentSection/ClientDetailsContentSection";
import NotesSection from "../../../components/NotesSection/NotesSection";

const ClientDetails = () => {
  const { id } = useParams();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedClient, setEditedClient] = useState({});

  useEffect(() => {
    fetchClientDetails();
  }, [id]);

  const fetchClientDetails = async () => {
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      // Fetch client and organizers
      const clientResponse = await fetchData(
        { request: `clients/${id}` },
        "GET",
        token
      );
      setClient(clientResponse.client);
      setEditedClient(clientResponse.client);
    } catch (error) {
      console.error("Error fetching client or organizers details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("profileImage", file);

    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      const response = await fetchData(
        { request: `clients/upload/${id}`, body: formData },
        "POST",
        token,
        true
      );

      await fetchClientDetails();
      setToastMessage(response.message);
      setToastType("success");
      setShowToast(true);
    } catch (error) {
      console.error("Error uploading image:", error);
      setToastMessage("Failed to upload image");
      setToastType("danger");
      setShowToast(true);
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveChanges = async () => {
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      await fetchData(
        { request: `clients/${id}`, body: editedClient },
        "PUT",
        token
      );
      await fetchClientDetails();
      setIsEditing(false);
      setToastMessage("הלקוח עודכן בהצלחה");
      setToastType("success");
      setShowToast(true);
    } catch (error) {
      console.error("Error updating client details:", error);
      setToastMessage("שגיאה בעדכון הלקוח");
      setToastType("danger");
      setShowToast(true);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedClient(client);
  };

  const handleInputChange = (e) => {
    setEditedClient({ ...editedClient, [e.target.name]: e.target.value });
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center mt-5">
        <LoaderComponent />
      </Container>
    );
  }

  if (!client) {
    return (
      <Container className="d-flex justify-content-center align-items-center mt-5">
        <p>Client not found.</p>
      </Container>
    );
  }

  return (
    <div className="container mb-4 d-flex flex-column gap-3">
      <div className="d-flex gap-2 justify-content-start align-items-center">
        <BackButton />
        <h1 className="font-weight-bold">כרטיס לקוח</h1>
      </div>

      <Row>
        <Col md={8} className="d-flex flex-column gap-3">
          <Row className="row-gap-2">
            <Col md={12}>
              <ClientDetailsHeader
                client={client}
                handleImageUpload={handleImageUpload}
              />
            </Col>
          </Row>
          <ClientCardTripsSection client={client} />
          <Row className="row-gap-2">
            <Col md={12}>
              <ClientDetailsContentSection
                client={client}
                isEditing={isEditing}
                editedClient={editedClient}
                handleInputChange={handleInputChange}
                handleCancelEdit={handleCancelEdit}
                handleEditToggle={handleEditToggle}
                handleImageUpload={handleImageUpload}
                handleSaveChanges={handleSaveChanges}
                setToastMessage={setToastMessage}
                setToastType={setToastType}
                fetchClientDetails={fetchClientDetails}
                setShowToast={setShowToast}
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <NotesSection id={client._id} context="client" />
        </Col>
      </Row>

      <ClientTransactions client={client} />

      <ToastComponent
        type={toastType}
        message={toastMessage}
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </div>
  );
};

export default ClientDetails;
