import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { fetchData } from "../../../../utils/fetchData";
import AreYouSureModal from "../../../../components/AreYouSureModal/AreYouSureModal";
import { CreateOrganizerModal } from "../../CreateOrganizerModal";

const ClientOrganizersTab = ({
  client,
  setToastType,
  setShowToast,
  setToastMessage,
}) => {
  const [organizers, setOrganizers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrganizer, setSelectedOrganizer] = useState(null);
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [organizerToRemove, setOrganizerToRemove] = useState(null);

  useEffect(() => {
    fetchOrganizers();
  }, [client._id]);

  // Fetch the list of organizers for the client
  const fetchOrganizers = async () => {
    setLoading(true);
    try {
      const token = getToken();

      const organizersResponse = await fetchData(
        { request: `clients/${client._id}/organizers` },
        "GET",
        token
      );

      const activeOrganizersSet = await fetchActiveOrganizers(token);

      const organizersWithStatus = organizersResponse.organizers?.map(
        (organizer) => ({
          ...organizer,
          isActive: activeOrganizersSet.has(organizer._id),
        })
      );

      setOrganizers(organizersWithStatus);
    } catch (error) {
      console.error("Error fetching organizers:", error);
      setToastMessage("שגיאה בטעינת הסדרנים");
      setToastType("danger");
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  // Fetch active organizers
  const fetchActiveOrganizers = async (token) => {
    try {
      const response = await fetchData(
        { request: `organizers/active-organizers` },
        "GET",
        token
      );

      return response && typeof response === "object"
        ? new Set(Object.keys(response))
        : new Set();
    } catch (error) {
      console.error("Error fetching active organizers:", error.message);
      return new Set();
    }
  };

  const getToken = () => {
    const userData = localStorage.getItem("userData");
    if (!userData) throw new Error("User is not authenticated");
    return JSON.parse(userData).token;
  };

  const handleOpenModal = (organizer = null) => {
    setSelectedOrganizer(organizer);
    setIsModalOpen(true);
  };

  const handleRemoveOrganizer = async () => {
    try {
      const token = getToken();

      await fetchData(
        { request: `organizers/${organizerToRemove._id}` },
        "DELETE",
        token
      );

      setOrganizers((prev) =>
        prev.filter((organizer) => organizer._id !== organizerToRemove._id)
      );

      setToastMessage("הסדרן הוסר בהצלחה");
      setToastType("success");
      setShowToast(true);
    } catch (error) {
      console.error("Error removing organizer:", error);
      setToastMessage("לא ניתן להסיר סדרן");
      setToastType("danger");
      setShowToast(true);
    } finally {
      setIsAreYouSureOpen(false);
    }
  };

  const handleShowAreYouSureModal = (organizer) => {
    setOrganizerToRemove(organizer);
    setIsAreYouSureOpen(true);
  };

  const closeAreYouSureModal = () => {
    setIsAreYouSureOpen(false);
    setOrganizerToRemove(null);
  };

  return (
    <div
      className="card-body"
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex w-100 justify-content-end">
          <Button className="table-btn" onClick={() => handleOpenModal()}>
            הוספת סדרן
          </Button>
        </div>
      </div>

      {loading ? (
        <p>טוען...</p>
      ) : organizers?.length > 0 ? (
        organizers?.map((organizer, index) => (
          <div key={organizer._id}>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p>
                  <strong className="font-weight-bold">שם הסדרן:</strong>{" "}
                  {organizer.firstName} {organizer.lastName}
                  {organizer.isActive && (
                    <span
                      style={{
                        color: "green",
                        fontSize: "24px",
                        marginLeft: "10px",
                      }}
                    >
                      ●
                    </span>
                  )}
                </p>
                <p>
                  <strong className="font-weight-bold">מספר נייד:</strong>{" "}
                  {organizer.phoneNumber}
                </p>
                <p>
                  <strong className="font-weight-bold">פעילות אחרונה:</strong>{" "}
                  {organizer.lastActive
                    ? new Date(organizer.lastActive).toLocaleString("he-IL")
                    : "לא נרשמה פעילות"}
                </p>
              </div>
              <div className="d-flex gap-2">
                <Button
                  variant="outline-secondary"
                  onClick={() => handleOpenModal(organizer)}
                >
                  <BsPencilSquare /> עריכה
                </Button>
                <Button
                  variant="outline-danger"
                  onClick={() => handleShowAreYouSureModal(organizer)}
                >
                  <BsTrash /> מחיקה
                </Button>
              </div>
            </div>
            {index < organizers?.length - 1 && <hr />}
          </div>
        ))
      ) : (
        <p>לא קיימים סדרנים ללקוח זה. לחץ על "הוספת סדרן" להוספה.</p>
      )}

      {/* Modals */}
      <CreateOrganizerModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        fetchOrganizers={fetchOrganizers}
        setToastType={setToastType}
        setToastMessage={setToastMessage}
        setShowToast={setShowToast}
        organizer={selectedOrganizer}
        setOrganizer={setSelectedOrganizer}
        client={[client]}
      />

      <AreYouSureModal
        isAreYouSureOpen={isAreYouSureOpen}
        closeAreYouSureModal={closeAreYouSureModal}
        handleApprove={handleRemoveOrganizer}
        modalTitle="האם אתה בטוח?"
        modalMessage={`האם אתה בטוח שברצונך להסיר את הסדרן ${organizerToRemove?.firstName} ${organizerToRemove?.lastName}?`}
      />
    </div>
  );
};

export default ClientOrganizersTab;
