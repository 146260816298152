import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ClientDetailsCardTripsItem from "./ClientDetailsCardTripsItem";
import { fetchData } from "../../../../utils/fetchData";
import { formatNumberWithCommas } from "../../../../utils/supportFunctions";

export default function ClientCardTripsSection({ client }) {
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getStartAndEndDates = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return {
      startDate: start.toISOString().split("T")[0],
      endDate: end.toISOString().split("T")[0],
    };
  };

  useEffect(() => {
    fetchSummary();
  }, [client._id]);

  const fetchSummary = async () => {
    setLoading(true);
    try {
      const dataObj = {
        request: `transactions/client/${client._id}/summary`,
      };
      const { startDate, endDate } = getStartAndEndDates();
      const response = await fetchData(dataObj, "GET", null, {
        startDate,
        endDate,
      });

      if (response?.summary) {
        setSummary(response.summary);
        setError(null);
      } else {
        setError("לא ניתן היה לטעון סיכום.");
      }
    } catch (err) {
      console.error(err);
      setError("שגיאה בטעינת סיכום.");
    } finally {
      setLoading(false);
    }
  };

  const getPlaceholder = () => "—";

  return (
    <Row>
      <Col md={4}>
        <ClientDetailsCardTripsItem
          title="נסיעות שנלקחו"
          tooltip="נסיעות שהלקוח הציע במערכת ולקוח אחר מבצע את הנסיעה עבורו"
          data={[
            {
              label: "כמות נסיעות",
              value: loading ? getPlaceholder() : summary?.totalTakenTrips,
            },
            {
              label: "סכום",
              value: loading
                ? getPlaceholder()
                : `${formatNumberWithCommas(
                    summary?.totalTakenRevenueWithVAT?.toFixed(2)
                  )}`,
            },
            {
              label: "עמלה",
              value: loading
                ? getPlaceholder()
                : `${formatNumberWithCommas(
                    summary?.totalTakenFeesWithVAT?.toFixed(2)
                  )}`,
            },
          ]}
          totalCalculation={
            loading
              ? getPlaceholder()
              : `${formatNumberWithCommas(
                  (
                    summary?.totalTakenRevenueWithVAT -
                    summary?.totalTakenFeesWithVAT
                  )?.toFixed(2)
                )}`
          }
        />
      </Col>
      <Col md={4}>
        <ClientDetailsCardTripsItem
          title="נסיעות שבוצעו"
          tooltip="נסיעות שהלקוח מבצע עבור לקוח אחר"
          data={[
            {
              label: "כמות נסיעות",
              value: loading ? getPlaceholder() : summary?.performedTrips,
            },
            {
              label: "סכום",
              value: loading
                ? getPlaceholder()
                : `${formatNumberWithCommas(
                    summary?.totalPerformedRevenueWithVAT?.toFixed(2)
                  )}`,
            },
            {
              label: "עמלה",
              value: loading
                ? getPlaceholder()
                : `${formatNumberWithCommas(
                    summary?.totalPerformedFeesWithVAT?.toFixed(2)
                  )}`,
            },
          ]}
          totalCalculation={
            loading
              ? getPlaceholder()
              : `${formatNumberWithCommas(
                  summary?.totalPerformedProfit?.toFixed(2)
                )}`
          }
        />
      </Col>
      <Col md={4}>
        <ClientDetailsCardTripsItem
          title="נסיעות שפורסמו"
          data={[
            {
              label: "כמות נסיעות",
              value: loading ? getPlaceholder() : summary?.totalPublishedTrips,
            },
            {
              label: "נסיעות פתוחות",
              value: loading ? getPlaceholder() : summary?.openTrips,
            },
            {
              label: "נסיעות סגורות",
              value: loading ? getPlaceholder() : summary?.closedTrips,
            },
            {
              label: "נסיעות שבוטלו",
              value: loading ? getPlaceholder() : summary?.canceledTrips,
            },
          ]}
        />
      </Col>
    </Row>
  );
}
