import React, { useEffect, useState } from "react";
import axios from "axios";

const OrderDetailsHistoryFetchData = ({ lineCode }) => {
  const [historyData, setHistoryData] = useState(null);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [historyTabError, setHistoryTabError] = useState(null);

  useEffect(() => {
    const fetchHistoryData = async () => {
      if (!lineCode) return;

      try {
        const url = `https://interfaceserviceapi.y-it.co.il/FcApiService/FcApiService.svc/InvokeFcApiService?authenticationToken=d8707759-45d2-492a-01a2-c430d1f6c82c&$action=get&$objects=DIARYCHANGELOG&$lineCode=${lineCode}`;
        const response = await axios.get(url);
        if (response?.data?.data?.diaryChangeLogObject) {
          setHistoryData(response.data.data.diaryChangeLogObject[0]);
          setHistoryTabError(null); // Clear any previous error
        } else {
          setHistoryTabError("שגיאה: לא נמצאו היסטוריית שינויים.");
        }
      } catch (err) {
        setHistoryTabError("שגיאה: לא נמצאו היסטוריית שינויים.");
        console.error("History fetch error:", err);
      } finally {
        setLoadingHistory(false);
      }
    };

    fetchHistoryData();
  }, [lineCode]);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  if (loadingHistory) return <div>טוען היסטוריה...</div>;
  if (historyTabError) return <div>{historyTabError}</div>;

  return (
    <div
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      {historyData?.changes?.map((change, index) => (
        <div key={index}>
          <div key={index} className="mb-3 d-flex flex-column">
            <div>
              <strong className="font-weight-bold">שינוי על ידי:</strong>{" "}
              {change.changedByName}
            </div>
            <div>
              <strong className="font-weight-bold">זמן שינוי:</strong>{" "}
              {formatDate(change.changeTime.split(" ")[0])}
            </div>
            <div>
              <strong className="font-weight-bold">אובייקט שינוי:</strong>{" "}
              {change.changeObject}
            </div>
            <div>
              <strong className="font-weight-bold">ערך ישן:</strong>{" "}
              {change.oldValue}
            </div>
            <div>
              <strong className="font-weight-bold">ערך חדש:</strong>{" "}
              {change.newValue}
            </div>
          </div>
          {index !== historyData?.changes?.length - 1 && <hr />}
        </div>
      ))}
    </div>
  );
};

export default OrderDetailsHistoryFetchData;
