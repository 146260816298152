import React, { useEffect, useState } from "react";
import {
  Table,
  Card,
  Form,
  Button,
  Tabs,
  Tab,
  Pagination,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { fetchData } from "../../../utils/fetchData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatNumberWithCommas } from "../../../utils/supportFunctions";
import { useNavigate } from "react-router-dom";

const ClientTransactions = ({ client }) => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState({
    allTrips: [],
    takenTrips: [],
    performedTrips: [],
    publishedTrips: [],
  });
  const [summary, setSummary] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("takenTrips");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    limit: 25,
    totalItems: {
      allTrips: 0,
      takenTrips: 0,
      performedTrips: 0,
      publishedTrips: 0,
    },
  });
  const [showDateModal, setShowDateModal] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());

  const userData = localStorage.getItem("userData");

  // Date range
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(1);
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());

  // Fetch transactions
  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const dataObj = {
        request: `transactions/client/${client._id}`,
      };
      const params = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        page: pagination.currentPage,
        limit: pagination.limit,
      };

      const response = await fetchData(dataObj, "GET", null, params);
      if (response) {
        setTransactions({
          allTrips: response.allTrips || [],
          takenTrips: response.takenTrips || [],
          performedTrips: response.performedTrips || [],
          publishedTrips: response.publishedTrips || [],
        });
        setPagination((prev) => ({
          ...prev,
          totalPages: response.totalPages || 1,
          totalItems: {
            allTrips: response.totalAllTrips || 0,
            takenTrips: response.totalTakenTrips || 0,
            performedTrips: response.totalPerformedTrips || 0,
            publishedTrips: response.totalPublishedTrips || 0,
          },
        }));
      } else {
        setError("לא נמצאו טרנזקציות.");
      }
    } catch (err) {
      setError("שגיאה בטעינת טרנזקציות.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch summary
  const fetchSummary = async () => {
    try {
      const dataObj = {
        request: `transactions/client/${client._id}/summary`,
      };
      const params = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };

      const response = await fetchData(dataObj, "GET", null, params);
      if (response?.summary) {
        setSummary(response.summary);
      } else {
        setError("לא ניתן היה לטעון סיכום.");
      }
    } catch (err) {
      setError("שגיאה בטעינת סיכום.");
      console.error(err);
    }
  };

  // Load data on mount or when pagination changes
  useEffect(() => {
    fetchTransactions();
    fetchSummary();
  }, [pagination.currentPage, pagination.limit]);

  // Handlers
  const handleLimitChange = (e) => {
    setPagination({
      ...pagination,
      limit: parseInt(e.target.value),
      currentPage: 1,
    });
  };

  const renderTableRows = (trips) =>
    trips.map((tx) => (
      <tr key={tx._id}>
        <td
          className="hover-link"
          onClick={() => navigate(`/dashboard/trip-details/${tx.id}`)}
        >
          {tx.id}
        </td>
        <td
          className="hover-link"
          onClick={() => navigate(`/dashboard/trip-details/${tx.id}`)}
        >
          {tx.tripDetails.lineCode}
        </td>
        <td
          className="hover-link"
          onClick={() => navigate(`/dashboard/trip-details/${tx.id}`)}
        >
          {tx.tripDetails.lineDescription}
        </td>
        <td>
          {new Date(tx.tripDetails.startTime).toLocaleDateString("he-IL")}
        </td>
        <td>{new Date(tx.tripDetails.endTime).toLocaleDateString("he-IL")}</td>
        <td>{formatNumberWithCommas(tx.priceWithVAT.toFixed(2))}</td>
        <td>{tx.feePercentage}%</td>
        <td>{formatNumberWithCommas(tx.feeWithVAT.toFixed(2))}</td>
        <td>{new Date(tx.transactionDate).toLocaleDateString("he-IL")}</td>
      </tr>
    ));

  const calculateSums = (trips) => {
    const totalLockedPrice = trips.reduce((sum, tx) => sum + tx.lockedPrice, 0);
    const totalLockedPriceWithVat = trips.reduce(
      (sum, tx) => sum + tx.priceWithVAT,
      0
    );
    const totalLockedFee = trips.reduce((sum, tx) => sum + tx.lockedFee, 0);
    const totalLockedFeeWithVat = trips.reduce(
      (sum, tx) => sum + tx.feeWithVAT,
      0
    );
    return {
      totalLockedPrice,
      totalLockedFee,
      totalLockedPriceWithVat,
      totalLockedFeeWithVat,
    };
  };

  const renderTable = (trips) => {
    const { totalLockedFeeWithVat, totalLockedPriceWithVat } =
      calculateSums(trips);

    if (trips.length === 0) {
      return (
        <div className="text-center my-5">
          <h3 className="font-weight-bold">לא נמצאו נתונים להצגה.</h3>
        </div>
      );
    }

    return (
      <div className="table-responsive" style={{ width: "100%" }}>
        <Table striped bordered hover style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>מזהה נסיעה</th>
              <th>קוד נסיעה</th>
              <th>תיאור נסיעה</th>
              <th>תאריך התחלה</th>
              <th>תאריך סיום</th>
              <th>מחיר כולל</th>
              <th>אחוז עמלה</th>
              <th>עמלה לתשלום</th>
              <th>תאריך עסקה</th>
            </tr>
          </thead>
          <tbody>{renderTableRows(trips)}</tbody>
          <tfoot>
            <tr>
              <td colSpan="5" className="text-end">
                <strong className="font-weight-bold">סכום כולל:</strong>
              </td>
              <td>
                <strong className="font-weight-bold">
                  {formatNumberWithCommas(totalLockedPriceWithVat.toFixed(2))}
                </strong>
              </td>
              <td></td>
              <td>
                <strong className="font-weight-bold">
                  {formatNumberWithCommas(totalLockedFeeWithVat.toFixed(2))}
                </strong>
              </td>
              <td></td>
            </tr>
          </tfoot>
        </Table>
      </div>
    );
  };

  const shouldRenderPagination = (tabKey) => {
    const totalItems = pagination.totalItems[tabKey];
    return totalItems > pagination.limit;
  };

  const renderPagination = (tabKey) => {
    if (!shouldRenderPagination(tabKey) || pagination.totalPages <= 1)
      return null;

    return (
      <div className="d-flex justify-content-between align-items-center mt-3 w-100">
        <Form.Select
          value={pagination.limit}
          onChange={handleLimitChange}
          style={{ width: "150px" }}
        >
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={75}>75</option>
          <option value={100}>100</option>
        </Form.Select>
        <Pagination>
          {Array.from({ length: pagination.totalPages }, (_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === pagination.currentPage}
              onClick={() =>
                setPagination({ ...pagination, currentPage: index + 1 })
              }
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
    );
  };

  const handleExportExcel = async (
    type,
    dateRange,
    customStartDate,
    customEndDate
  ) => {
    try {
      const userDataObj = JSON.parse(userData);
      if (!userDataObj?.token) {
        throw new Error("No auth token found");
      }

      let exportStartDate, exportEndDate;

      switch (dateRange) {
        case "current":
          exportStartDate = startDate.toISOString();
          exportEndDate = endDate.toISOString();
          break;
        case "month":
          const now = new Date();
          exportStartDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            1
          ).toISOString();
          exportEndDate = new Date(
            now.getFullYear(),
            now.getMonth() + 1,
            0
          ).toISOString();
          break;
        case "all":
          exportStartDate = null;
          exportEndDate = null;
          break;
        case "custom":
          exportStartDate = customStartDate?.toISOString();
          exportEndDate = customEndDate?.toISOString();
          break;
        default:
          throw new Error("Invalid date range");
      }

      const dataObj = {
        request: `clients/transactions/export/${client._id}`,
      };

      const params = {
        type, // Keep the trip type (e.g., takenTrips, performedTrips)
        ...(exportStartDate && { startDate: exportStartDate }),
        ...(exportEndDate && { endDate: exportEndDate }),
        _t: new Date().getTime(),
      };

      const response = await fetchData(
        dataObj,
        "GET",
        userDataObj.token,
        params
      );

      const blob = new Blob([response], { type: "text/csv;charset=utf-8" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = `trips-${type}-${
        new Date().toISOString().split("T")[0]
      }.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error exporting:", error);
      alert("אירעה שגיאה בייצוא לקובץ");
    }
  };

  const renderExportButton = (type) => (
    <div className="export-button-container mb-3 d-flex justify-content-end">
      <Dropdown>
        <Dropdown.Toggle variant="success" id={`export-dropdown-${type}`}>
          <i className="fas fa-file-excel me-2"></i>
          ייצא לאקסל
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleExportExcel(type, "current")}>
            <i className="fas fa-filter me-2"></i>
            ייצא לפי סינון נוכחי
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleExportExcel(type, "month")}>
            <i className="fas fa-calendar me-2"></i>
            ייצא חודש נוכחי
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleExportExcel(type, "all")}>
            <i className="fas fa-calendar-alt me-2"></i>
            ייצא את כל הנתונים
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowDateModal(true)}>
            <i className="fas fa-calendar-plus me-2"></i>
            ייצא לפי טווח מותאם
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  // Rendering
  if (loading) return <div>טוען...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="w-100">
      <Card className="mb-4 border border-1">
        <Card.Header>
          <h2 className="font-weight-bold">היסטוריית נסיעות</h2>
        </Card.Header>
        <Card.Body>
          {/* Filters */}
          <Form className="mb-4">
            <div className="row">
              <div className="col-md-4 d-flex gap-2 align-items-center">
                <Form.Label className="p-0 m-0 font-weight-bold">
                  תאריך התחלה:
                </Form.Label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="col-md-4 d-flex gap-2 align-items-center">
                <Form.Label className="p-0 m-0 font-weight-bold">
                  תאריך סיום:
                </Form.Label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="col-md-4 d-flex align-items-end">
                <Button
                  variant="primary"
                  onClick={() => {
                    fetchTransactions();
                    fetchSummary();
                  }}
                >
                  חפש
                </Button>
              </div>
            </div>
          </Form>

          {/* Summary */}
          <div className="summary-container mb-4 p-3 border rounded">
            <h5 className="text-primary font-weight-bold mb-3">סיכום נסיעות</h5>
            <p className="text-muted">
              הנתונים המוצגים מבוססים על התאריכים שנבחרו:
              <br />
              <strong>מתאריך:</strong> {startDate.toLocaleDateString("he-IL")}{" "}
              <strong>עד תאריך:</strong> {endDate.toLocaleDateString("he-IL")}
            </p>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="d-flex flex-column align-items-start">
                  <h6 className="text-success font-weight-bold">
                    נסיעות שנלקחו
                  </h6>
                  <p>
                    <strong className="font-weight-bold">כמות:</strong>{" "}
                    {summary.totalTakenTrips} נסיעות
                  </p>
                  <p>
                    <strong className="font-weight-bold">סה"כ מחזור:</strong> ₪
                    {formatNumberWithCommas(
                      summary.totalTakenRevenueWithVAT?.toFixed(2)
                    )}
                  </p>
                  <p>
                    <strong className="font-weight-bold">סה"כ עמלות:</strong> ₪
                    {formatNumberWithCommas(
                      summary.totalTakenFeesWithVAT?.toFixed(2)
                    )}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column align-items-start">
                  <h6 className="text-warning font-weight-bold">
                    נסיעות שפורסמו
                  </h6>
                  <p>
                    <strong className="font-weight-bold">כמות:</strong>{" "}
                    {summary.totalPublishedTrips} נסיעות
                  </p>
                  <p>
                    <strong className="font-weight-bold">סה"כ מחזור:</strong> ₪
                    {formatNumberWithCommas(
                      summary.totalPublishedRevenueWithVAT?.toFixed(2)
                    )}
                  </p>
                  <p>
                    <strong className="font-weight-bold">סה"כ עמלות:</strong> ₪
                    {formatNumberWithCommas(
                      summary.totalPublishedFeesWithVAT?.toFixed(2)
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Tabs */}
          <Tabs
            id="transactions-tabs"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mb-3"
          >
            <Tab
              eventKey="takenTrips"
              title={
                <div>
                  נסיעות שנלקחו
                  <span className="font-weight-light text-gray">{` (${pagination.totalItems.takenTrips})`}</span>
                </div>
              }
            >
              {renderExportButton("takenTrips")}
              {renderTable(transactions.takenTrips)}
              <div>{renderPagination("takenTrips")}</div>
            </Tab>
            <Tab
              eventKey="performedTrips"
              title={
                <div>
                  נסיעות שבוצעו
                  <span className="font-weight-light text-gray">{` (${pagination.totalItems.performedTrips})`}</span>
                </div>
              }
            >
              {renderExportButton("performedTrips")}
              {renderTable(transactions.performedTrips)}
              <div>{renderPagination("performedTrips")}</div>
            </Tab>
            <Tab
              eventKey="publishedTrips"
              title={
                <div>
                  נסיעות שפורסמו
                  <span className="font-weight-light text-gray">{` (${pagination.totalItems.publishedTrips})`}</span>
                </div>
              }
            >
              {renderExportButton("publishedTrips")}
              {renderTable(transactions.publishedTrips)}
              <div>{renderPagination("publishedTrips")}</div>
            </Tab>
            <Tab
              eventKey="allTrips"
              title={
                <div>
                  כל הנסיעות
                  <span className="font-weight-light text-gray">{` (${pagination.totalItems.allTrips})`}</span>
                </div>
              }
            >
              {renderExportButton("allTrips")}
              {renderTable(transactions.allTrips)}
              <div>{renderPagination("allTrips")}</div>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
      <Modal show={showDateModal} onHide={() => setShowDateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>בחר טווח תאריכים לייצוא</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>תאריך התחלה</Form.Label>
              <DatePicker
                selected={customStartDate}
                onChange={(date) => setCustomStartDate(date)}
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>תאריך סיום</Form.Label>
              <DatePicker
                selected={customEndDate}
                onChange={(date) => setCustomEndDate(date)}
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDateModal(false)}>
            סגור
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleExportExcel(
                activeTab,
                "custom",
                customStartDate,
                customEndDate
              );
              setShowDateModal(false);
            }}
          >
            ייצא
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClientTransactions;
