import React from "react";

const Label = ({ title, content }) => {
  const containerStyle = {
    padding: "8px",
    borderRadius: "8px",
    textAlign: "right",
    fontWeight: "bold",
    fontSize: "16px",
    backgroundColor: "#f5f6f8",
    textColor: "#000000",
  };

  return (
    <div style={containerStyle}>
      <span className="font-weight-bold">{title}: </span>
      <span>{content}</span>
    </div>
  );
};

export default Label;
