import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { fetchData } from "../../../utils/fetchData";

import OrderDetailsHeaderSection from "./OrderDetailsHeaderSection";
import OrderDetailsRevenueSection from "./OrderDetailsRevenueSection";
import OrderDetailsContentSection from "./OrderDetailsContentSection";
import BackButton from "../../../components/BackButton/BackButton";
import NotesSection from "../../../components/NotesSection/NotesSection";

const TripDetails = () => {
  const { id } = useParams();
  const [tripDetails, setTripDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTripDetails = async () => {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData)?.token;

      try {
        const dataObj = { request: `trip/${id}` };
        const response = await fetchData(dataObj, "GET", token);
        if (response?.trip) {
          setTripDetails(response?.trip);
        } else {
          setError("לא נמצאו פרטי הנסיעה.");
        }
      } catch (err) {
        setError("שגיאה בטעינת פרטי הנסיעה.");
        console.error("Fetch error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchTripDetails();
  }, [id]);

  if (loading) return <div>טוען...</div>;
  if (error) return <div>שגיאה: {error}</div>;

  return (
    <div className="container mb-4">
      <div className="d-flex gap-3 flex-column">
        <div className="d-flex gap-2 justify-content-start align-items-center">
          <BackButton />
          <h1 className="font-weight-bold">כרטיס נסיעה {tripDetails?.id}#</h1>
        </div>
        <OrderDetailsHeaderSection tripDetails={tripDetails} />
        <OrderDetailsRevenueSection tripDetails={tripDetails} />
        <Row>
          <Col md={8}>
            <OrderDetailsContentSection tripDetails={tripDetails} />
          </Col>
          <Col md={4}>
            <NotesSection id={tripDetails.id} context="trip" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TripDetails;
