import React, { useState } from "react";
import DangerZone from "../DangerZone";

const ClientSettingsTab = ({
  client,
  setToastType,
  setShowToast,
  setToastMessage,
  fetchClientDetails,
}) => {
  return (
    <div
      className="card-body"
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      <DangerZone
        client={client}
        setToastType={setToastType}
        setShowToast={setShowToast}
        setToastMessage={setToastMessage}
        fetchClientDetails={fetchClientDetails}
      />
    </div>
  );
};

export default ClientSettingsTab;
