import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

export default function AddVatModal({
  setShowVatModal,
  setNewVat,
  newVat,
  isLoading,
  showVatModal,
  addVatRate,
}) {
  return (
    <>
      <Button
        variant="primary"
        className="mt-3"
        onClick={() => setShowVatModal(true)}
      >
        הוסף אחוז מע"מ חדש
      </Button>

      <Modal show={showVatModal} onHide={() => setShowVatModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>הוספת אחוז מע"מ חדש</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="newVatRate" className="mb-3">
            <Form.Label>אחוז מע"מ</Form.Label>
            <Form.Control
              type="number"
              value={newVat.rate}
              min="0"
              onChange={(e) =>
                setNewVat({ ...newVat, rate: parseFloat(e.target.value) })
              }
            />
          </Form.Group>
          <Form.Group controlId="newVatDate" className="mb-3">
            <Form.Label>תאריך תחולה</Form.Label>
            <Form.Control
              type="date"
              value={newVat.effectiveDate}
              onChange={(e) =>
                setNewVat({ ...newVat, effectiveDate: e.target.value })
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowVatModal(false)}>
            ביטול
          </Button>
          <Button
            variant="primary"
            onClick={addVatRate}
            disabled={isLoading || !newVat.rate || !newVat.effectiveDate}
          >
            הוסף
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
